import { render, staticRenderFns } from "./PropertiesStackedChar.vue?vue&type=template&id=3cd858ba&scoped=true&"
import script from "./PropertiesStackedChar.vue?vue&type=script&lang=js&"
export * from "./PropertiesStackedChar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cd858ba",
  null
  
)

export default component.exports