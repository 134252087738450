<template>
  <div>
    <b-card class="contract-status">
      <h4>{{$t('Properties')}}</h4>
      <b-row class="mb-1">
        <b-col cols="6">
          <b-nav>
            <b-nav-item v-for="option in statusNavOptions" v-bind:active="statusNav===option.key" @click="()=>onNavClick(option.key)" v-bind:key="option.key">
              {{$t(`contractStats.${option.value}`)}}
            </b-nav-item>
          </b-nav>
        </b-col>
        <b-col cols="6" class="text-right">
          <b-form-checkbox inline switch size="sm" v-model="withContract">{{$t('contractStats.withContract')}}</b-form-checkbox>
          <b-form-input class="d-inline-block" style="width: 40%" size="sm" v-model="filter" :placeholder="`${$t('search')}...`"></b-form-input>
        </b-col>
      </b-row>

      <div>
        <b-table small striped hover :items="tableItems" :fields="tableFields" show-empty  :busy.sync="loading" v-bind:filter="filter"
                 responsive
                 :current-page="pagination.currentPage" :per-page="pagination.perPage" @row-clicked="onRowClicked">
          <template #empty>
            <h4 class="text-center">{{$t('nodatafound')}}</h4>
          </template>
          <template #table-busy>
            <div class="text-center">
              <b-spinner ></b-spinner>
            </div>
          </template>
          <template #cell(fullAddress)="data">
            <b-link @click="()=>$router.push({path: `/real_estate/edit/${data.item.id}`})">{{data.value}}</b-link>

          </template>
          <template #cell(lastupdate)="data">
            <span v-if="data.item.month !== '0'">
              {{data.item.month}}-{{data.item.year}}
            </span>
          </template>

          <template #cell(platform)="data">
            <platform-logo v-bind:platform="data.value">
              {{data.value}} €
            </platform-logo>
          </template>

          <template #cell(totalIncome)="data">
            {{data.value | numberFormatter}} €
          </template>

          <template #cell(totalNetIncome)="data">
            <span :class="{'text-danger': data.value < 0, 'text-success': data.value > 0}">
               {{data.value | numberFormatter}} €
            </span>

          </template>

          <template #cell(platformCommission)="data">
            {{data.item.externalPlatformCommission |numberFormatter}} € + {{data.item.externalPlatformTransactionFee | numberFormatter}} €
          </template>

          <template #cell(managementFee)="data">
            {{data.value | numberFormatter}} €
          </template>
        </b-table>
        <div class="text-center">
          <div class="d-inline-block">
            <b-pagination
                v-model="pagination.currentPage"
                :total-rows="totalRows"
                :per-page="pagination.perPage"
                size="sm"
                align="center"
                style="width: 100%"
            >
            </b-pagination>
          </div>

          <b-select  v-model="pagination.perPage" :options="pagination.pageOptions" style="width: 80px" class="ml-1 d-inline-block" size="sm"/>
        </div>

      </div>
    </b-card>
    <b-modal size="xl" :title="$t('contractStats.reservationTable')" centered ref="contracts-modal" hide-footer>
      <contract-rent-stats-table no-stats :loading="loading" :contracts="selectedContracts" />
    </b-modal>
  </div>
</template>
<script>
import PlatformLogo from "@/components/widgets/PlatformLogo.vue";
import ContractRentStatsTable from "@/components/panels/dashboard/contractRentStatsTable.vue";

export default {
  name: "contractMonthlyExpensesTable",
  components: {ContractRentStatsTable, PlatformLogo},
  props: {
    loading: {
      type: Boolean,
      default: () => false
    },
    stats: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    statusNavOptions: [{key:'expenses', value:'expenses'}, {key:'management', value:'management'}],
    statusNav: 'expenses',
    filter:'',
    withContract: false,
    selectedProperty: null,
    pagination:{
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, 25, { value: 50, text: "50" }],
    },
    sort:{
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
    }
  }),
  computed:{
    tableItems() {
      if (this.stats == null)
        return [];
      if(!this.withContract) {
        return Object.values(this.stats.realEstatesCalculations || {}).map(item => ({...item, ...item.expenses, monthAndYear: item.month, id: item.id}));
      }else {
        return Object.values(this.stats.realEstatesCalculations || {})
            .filter(item => item.totalOfContracts > 0)
            .map(item => ({...item, ...item.expenses, monthAndYear: item.month, id: item.id}));
      }
    },
    totalRows(){
      return this.tableItems.length
    },
    tableFields(){
      let $this = this
      if(this.statusNav === "expenses") {
        return [
          {key: 'fullAddress', label: $this.$t('property'), sortable: true},
          {key: 'monthAndYear', label: $this.$t('month'), sortable: true},
          {key: 'lastupdate', label: $this.$t('lastupdate'), sortable: true},
          {key: 'rent', label: $this.$t('rent'), sortable: true},
          {key: 'internet', label: $this.$t('internet'), sortable: true},
          {key: 'gas', label: $this.$t('gas'), sortable: true},
          {key: 'electricity', label: $this.$t('electricity'), sortable: true},
          {key: 'insurance', label: $this.$t('insurance'), sortable: true},
          {key: 'damage', label: $this.$t('damage'), sortable: true},
          {key: 'cleaning', label: $this.$t('cleaning'), sortable: true},
        ]
      }else {
        return [
          {key: 'fullAddress', label: $this.$t('property'), sortable: true},
          {key: 'monthAndYear', label: $this.$t('month'), sortable: true},
          {key: 'totalOfContracts', label: $this.$t('contractStats.totalOfContracts'), sortable: true},
          {key: 'totalOfNights', label: $this.$t('contractStats.nights'), sortable: true},
          {key: 'totalIncome', label: $this.$t('contractStats.totalIncome'), sortable: true},
          {key: 'platformCommission', label: $this.$t('contractStats.platformsCommissions'), sortable: true},
          {key: 'totalNetIncome', label: $this.$t('contractStats.totalNetIncome'), sortable: true},
          {key: 'managementFee', label: $this.$t('contractStats.management'), sortable: true},
        ]
      }
    },
    selectedContracts(){
      if(this.selectedProperty == null)
        return [];
      return this.stats.contracts.filter(contract => contract.propertyId === this.selectedProperty)
          .map(contract => ({...contract,
            platform: contract.bnbStyleRent.platform,
            propertyAddress: contract.propertyAddress,
            clientName: ("" + (contract.client.firstname || "") + " " + (contract.client.lastname || ""))
          }))
    }
  },
  methods:{
    onNavClick(status){
      this.statusNav = status
      this.pagination.currentPage = 1
    },
    onRowClicked(row){
      if(row.totalOfContracts === 0)
        return
      this.selectedProperty = row.id
      this.$refs['contracts-modal'].show()
      // this.$router.push({path: `/real_estate/edit/${row.id}`})
    }
  }
}
</script>


<style lang="scss">
.contract-status {
  .nav-link.active {
    text-decoration: underline
  }
}
</style>
