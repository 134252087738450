<template>
  <div>
    <div v-if="!loading && hasData ">
      <vue-apex-charts
          ref="propertiesBarChart"
          type="bar"
          height="350"
          :options="chartOptions"
          :series="series"
      />
      <div>
        <b-row>
          <b-col cols="12" md="6" lg="4" v-for="(propertie, index) in incomesByProperties" v-bind:key="propertie.fullAddress">
            {{index+1}}) {{propertie.fullAddress}} ({{propertie.totalOfContracts}} {{ $t('contracts')}})
          </b-col>
        </b-row>

      </div>
    </div>
    <div v-else-if="!hasData" style="margin-top: 150px; text-align: center">
      <b-alert show variant="warning" class="mt-2">
        {{$t('nodatafound')}}
      </b-alert>
    </div>
    <div class="mt-1" v-else style="max-height: 250px;overflow: hidden">
      <b-skeleton-img></b-skeleton-img>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import {mapGetters} from "vuex";

export default {
  name: "PropertiesStackedChar",
  components: {VueApexCharts},
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    incomesByProperties: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    incomesByProperties() {
      if (this.$refs['propertiesBarChart'] != null) {
        this.$refs['propertiesBarChart'].refresh()
      }
    }
  },
  computed: {
    ...mapGetters('appConfig', ['isDarkMode']),
    chartOptions() {
      let $this = this
      return {
        chart: {
          id: 'properties-bar-chart',
          stacked: true,
          // stackType: '100%',
          foreColor: $this.isDarkMode ? '#fafafa' :'#373d3f'
        },
        plotOptions: {
          bar: {
            horizontal: false
          },
        },
        dataLabels: {
          enabled: true,
          formatter: (val, { seriesIndex, w }) => {
            return val.toFixed(2) + ' €'
          },
        },
        xaxis: {
          categories: $this.incomesByProperties,
          labels: {
            show: true,
            formatter: (val) => {
              return `${val.fullAddress.substring(0, 10)}... (${val.totalOfContracts})`
            },
          },
          tooltip: {
            enabled: true,
            formatter: (val) => {
              return `${val.fullAddress} (${val.totalOfContracts} ${this.$t('contracts')}) €`
            },
          },
        },
        yaxis: {
          labels: {
            show: true,
            formatter: (val) => {
              return val.toFixed(2) + ' €'
            },
          },
        },
        //Add red and green colors
        colors: ['#ff4343', '#ff9f43', '#ff437b', '#c2aa8e', '#28c76f'],
        // labels: $this.incomesByProperties.map((checkin) => `${checkin.fullAddress} (${checkin.totalOfContracts})`),
      }
    },
    series() {
      let $this = this;
      return [
        {
          name: $this.$t('contractStats.commissions'),
          data: $this.incomesByProperties.map((checkin) => parseFloat(checkin.totalExternalPlatformCommission.toFixed(2)))
        },
        {
          name: $this.$t('contractStats.expenses'),
          data: $this.incomesByProperties.map((checkin) => parseFloat(checkin.totalExpensesWithoutRent.toFixed(2)))
        },
        {
          name: $this.$t('contractStats.rent'),
          data: $this.incomesByProperties.map((checkin) => parseFloat((checkin.rent || 0).toFixed(2)))
        },
        {
          name: $this.$t('contractStats.management'),
          data: $this.incomesByProperties.map((checkin) => parseFloat(checkin.managementFee.toFixed(2)))
        },
        {
          name: $this.$t('contractStats.netIncome'),
          data: $this.incomesByProperties.map((checkin) => parseFloat(checkin.totalNetIncome.toFixed(2)))
        }
      ];
    },
    hasData(){
      return this.incomesByProperties != null && this.incomesByProperties.length > 0
    }
  },
  methods: {
    refresh() {
      if (this.$refs['propertiesBarChart'] != null) {
        this.$refs['propertiesBarChart'].refresh()
      }
    },
  }
}
</script>


<style scoped>

</style>
