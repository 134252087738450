<template>
  <div>
    <div v-if="!loading && series.length > 0 ">
      <vue-apex-charts
          ref="capacityRadialChart"
          type="radialBar"
          height="350"
          :options="chartOptions"
          :series="series"
      />
      <div class="text-center font-medium-2">
        <p class="d-inline-block p-0 my-0 mr-1" v-b-tooltip :title="$t('properties')"><i class="fa-solid fa-house"></i> {{capacityData.totalOfProperties}}</p>
        <p class="d-inline-block p-0 my-0 mr-1" v-b-tooltip :title="$t('contractStats.totalOfReservationsNights')"><i class="fa-solid fa-house-circle-check"></i> {{capacityData.totalOfNights}}</p>
        <p class="d-inline-block p-0 my-0 mr-1" v-b-tooltip :title="$t('contractStats.totalOfContracts')"><i class="fa-solid fa-file-contract"></i> {{capacityData.totalOfContracts}}</p>
        <p class="d-inline-block p-0 my-0 mr-1" v-b-tooltip :title="$t('contractStats.totalOfDaysByProperty')"><i class="fa-solid fa-calendar-days"></i> {{capacityData.totalDays}}</p>
      </div>
    </div>
    <div v-else-if="series.length === 0" style="margin-top: 150px; text-align: center">
      <b-alert show variant="warning" class="mt-2">
        {{ $t('nodatafound') }}
      </b-alert>
    </div>
    <div class="mt-1" v-else style="max-height: 250px;overflow: hidden">
      <b-skeleton-img></b-skeleton-img>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import {mapGetters} from "vuex";

export default {
  name: "CapacityRadialChart",
  components: {VueApexCharts},
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    capacityData: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    capacityData() {
      if (this.$refs['capacityRadialChart'] != null) {
        this.$refs['capacityRadialChart'].refresh()
      }
    }
  },
  computed: {
    ...mapGetters('appConfig', ['isDarkMode']),
    chartOptions() {
      let $this = this
      return {
        chart: {height: 280, type: "radialBar"},
        plotOptions: {
          radialBar: {
            hollow: {margin: 15, size: "70%"},
            dataLabels: {
              showOn: "always",
              name: {offsetY: -10, show: true, color: "#888", fontSize: "13px"},
              value: {color: "#111", fontSize: "30px", show: true}
            }
          }
        },
        stroke: {
          lineCap: "round",
        },
        labels: [$this.$t('contractStats.capacity')],
      };
    },
    series() {
      if(this.capacityData == null)
        return null
      let totalDays = (this.capacityData?.totalDays || 0) * this.capacityData.totalOfProperties;
      if (totalDays === 0) return [0]
      let totalReserved = (this.capacityData?.totalOfNights || 0);
      let percentage = ((totalReserved / totalDays) * 100).toFixed(2);
      return [parseFloat(percentage)]
    }
  },
  methods: {}
}
</script>


<style scoped>

</style>
