<template>
<div class="contract-status">

  <b-row class="mb-1">
    <b-col cols="9">
      <b-nav v-if="!noStats">
        <b-nav-item v-for="option in statusNavOptions" v-bind:active="statusNav===option.key" @click="()=>onNavClick(option.key)" v-bind:key="option.key">
          {{$t(`contractStats.${option.value}`)}}
          <span v-if="stats[option.key].length > 0">({{ stats[option.key].length }})</span>
        </b-nav-item>
      </b-nav>
    </b-col>
    <b-col cols="3">
      <b-form-input size="sm" v-model="filter" :placeholder="`${$t('search')}...`"></b-form-input>
    </b-col>
  </b-row>

  <div>
    <b-table small striped hover :items="tableItems" :fields="tableFields" show-empty  :busy.sync="loading" v-bind:filter="filter"
             responsive
             :current-page="pagination.currentPage" :per-page="pagination.perPage" @row-clicked="onRowClicked">
      <template #empty>
        <h4 class="text-center">{{$t('nodatafound')}}</h4>
      </template>
      <template #table-busy>
        <div class="text-center">
          <b-spinner ></b-spinner>
        </div>
      </template>
      <template #cell(platform)="data">
        <platform-logo v-bind:platform="data.value">
          {{data.value}}
        </platform-logo>
      </template>
      <template #cell(moveDate)="data">
        {{data.value | moment($t('timeFormat.date.format'))}}
      </template>
      <template #cell(endDate)="data">
        {{data.value | moment($t('timeFormat.date.format'))}}
      </template>

    </b-table>

    <div class="text-center">
      <div class="d-inline-block">
        <b-pagination
            v-model="pagination.currentPage"
            :total-rows="totalRows"
            :per-page="pagination.perPage"
            size="sm"
        >
        </b-pagination>
      </div>

      <b-select v-model="pagination.perPage" :options="pagination.pageOptions" style="width: 80px"
                class="ml-1 d-inline-block" size="sm"/>
    </div>
  </div>

</div>
</template>
<script>
import PlatformLogo from "@/components/widgets/PlatformLogo.vue";

export default {
  name: "contractRentStatsTable",
  components: {PlatformLogo},
  props: {
    loading: {
      type: Boolean,
      default: () => false
    },
    stats: {
      type: Object,
      default: () => ({checkin: [], checkout: [], inRange: 0, staying: [], cancelled: []})
    },
    noStats: {
      type: Boolean,
      default: () => false
    },
    contracts: {
      type: Array,
      default: () => ([])
    }
  },
  data: () => ({
    statusNavOptions: [{key:'checkin', value:'checkins'}, {key:'checkout', value:'checkouts'},  {key:'staying', value:'staying'}, {key:'cancelled',value: 'cancellations'}, {key:'overBookings',value: 'overbooking'}],
    statusNav: 'checkin',
    filter:'',
    pagination:{
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, 25, { value: 50, text: "50" }],
    },
    sort:{
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
    }
  }),
  computed:{
    tableItems(){
      if (this.noStats)
        return this.contracts || []
      else if(this.statusNav !== "overBookings")
        return this.stats[this.statusNav]
      else
        return (this.stats[this.statusNav] || 0).map(calc => ({...this.stats.checkin.find(contract => contract.contractId === calc.contractId), ...calc}))

    },
    totalRows(){
      return this.tableItems.length
    },
    tableFields(){
      let fields =  [
        {key: 'platform', label: 'Platform', sortable: true},
        {key: 'propertyAddress', label: 'property', sortable: true},
        {key: 'clientName', label: 'client', sortable: true},
        {key: 'moveDate', label: 'Checkin', sortable: true },
        {key: 'endDate', label: 'Checkout', sortable: true},
      ]
      if(!this.noStats && (this.statusNav === 'checkin' || this.statusNav === 'checkout')){
        fields.push({key: 'amount', label: 'payment', sortable: true})
        fields.push({key: 'commissionAmount', label: 'commission', sortable: true})
      }
      return fields
    }
  },
  methods:{
    onNavClick(status){
      this.statusNav = status
      this.pagination.currentPage = 1
    },
    onRowClicked(row){
      this.$router.push({path: `/real_estate/contracts/rent/${row.contractId}`})
    }
  }
}
</script>


<style lang="scss">
.contract-status {
  .nav-link.active {
    text-decoration: underline
  }
}
</style>
