<template>
  <div class="mb-2">
    <div class="text-center">
      <div class="d-inline-block px-1" :style="`width: ${buttonWidth}`" v-for="option in options" :key="option.text">
        <b-button
            block
            :variant="selected !== option.text ? 'primary' : 'outline-primary'"
            size="sm"
            @click="onOptionSelected(option)"
            class="text-nowrap overflow-hidden d-flex align-items-center justify-content-center"
        >
          <span class="d-inline-block text-center text-capitalize">{{ option.text }}</span>
        </b-button>
      </div>
      <div id="calendar-bar-custom-range" v-if="enableCustom" class="d-inline-block px-1" :style="`width: ${buttonWidth}`">
        <b-button block
                  :variant="selected !== 'custom' ? 'primary' : 'outline-primary'"
                 :class="{ 'outline-primary': selected !== 'custom' }" size="sm"
                  class="text-nowrap overflow-hidden d-flex align-items-center justify-content-center"
        >
          <span class="d-inline-block text-center">{{ $t('calendarPicker.custom') }}</span>
        </b-button>
      </div>
      <div v-if="selected==='custom'" style="position: absolute; right: 45px;">
        {{ customRange.start | moment($t('timeFormat.dateMonth.format')) }} -
        {{ customRange.end | moment($t('timeFormat.dateMonth.format')) }}
      </div>

      <b-popover target="calendar-bar-custom-range" title="Choose dates" placement="bottomleft">
        <month-picker-range v-model="customRange" @input="onPickerInput"/>
      </b-popover>
    </div>
  </div>
</template>


<script>
import MonthPickerRange from "@/components/widgets/calendar/monthPickerRange.vue";

export default {
  name: 'CalendarMonthlyBar',
  components: {MonthPickerRange },
  data: () => ({
    selected: 'today',
    customRange: {start: null, end: null},
  }),
  props: {
    value: {
      default: null
    },
    startKey: {
      default: 'start'
    },
    endKey: {
      default: 'end'
    },
    enableCustom: {
      default: () => true
    },
    maxOptionsSize: {
      default: () => 10
    },
    futureOptionsSize: {
      default: () => 0
    },
  },
  created() {
    this.onOptionSelected(this.options.find(o => o.currentMonth));
  },
  computed: {
    options(){
      let maxOptionsSize = this.maxOptionsSize;
      let moment = this.$moment;
      let months = [];
      for (let i = (this.futureOptionsSize || 0); i > 0; i--) {
        maxOptionsSize--;
        let start = moment().add(i, 'months').startOf('month').format('YYYY-MM-DD');
        let end = moment().add(i, 'months').endOf('month').format('YYYY-MM-DD');
        months.push({
          value: {start, end},
          text: moment().add(i, 'months').format('MMMM YY'),
          currentMonth: false
        })
      }
      for (let i = 0; i < 12 && maxOptionsSize >= 0 ; i++) {
        maxOptionsSize--;
        let start = moment().subtract(i, 'months').startOf('month').format('YYYY-MM-DD');
        let end = moment().subtract(i, 'months').endOf('month').format('YYYY-MM-DD');
        months.push({
          value: {start, end},
          text: moment().subtract(i, 'months').format('MMMM YY'),
          currentMonth: i===0
        })
      }
      return months
    },
    buttonWidth() {
      return `${Math.floor(100 / (this.options.length + 1))}%`;
    }
  },
  methods: {
    onPickerInput(value) {
      console.log('onPickerInput', value);
      this.onOptionSelected({text: 'custom', value});
    },
    onOptionSelected(option) {
      this.selected = option.text;
      console.log('onOptionSelected', {option, selected: this.selected, customRange: this.customRange})
      this.closeAllPopovers();
      this.customRange = option.value;
      let value = option.value;
      this.$emit('input', {[this.startKey]: value.start, [this.endKey]: value.end});

    },
    closeAllPopovers () {
      this.$root.$emit('bv::hide::popover')
    },
  }
};
</script>

<style lang="scss">
.calendar-bar-custom-range{
  .month-picker__container{
    left: -200px;
  }
}
</style>
