<template>
  <div class="custom-month-picker">
    <b-row>
      <b-col cols="12" lg="6">
        <month-picker-input
            ref="startPicker"
            v-model="pickerValue.start"
            editable-year
            :default-month="defaultPickerValue.start.monthIndex"
            :default-year="defaultPickerValue.start.year"
            :lang="deviceBasicLang"
            @input="onStartInput"
        ></month-picker-input>
      </b-col>
      <b-col cols="12" lg="6">
        <month-picker-input
            ref="endPicker"
            v-model="pickerValue.end"
            editable-year
            :min-date="minDateValue"
            :default-month="defaultPickerValue.end.monthIndex"
            :default-year="defaultPickerValue.end.year"
            :lang="deviceBasicLang"
            :active="pickerValue.start != null"
            @input="onEndInput"
            @click-outside="setInputValue"
        ></month-picker-input>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "monthPickerRange",
  props: {
    value: {
      default: () => ({})
    },
    startKey: {
      default: "start"
    },
    endKey: {
      default: "end"
    }
  },
  data: () => ({
    loadingData: true,
    defaultPickerValue: {
      start: {year: null, monthIndex: null},
      end: {year: null, monthIndex: null}
    },
    pickerValue: {
      start: null,
      end: null
    },
    momentValue: {
      start: null,
      end: null
    },
    stringValue: {
      start: null,
      end: null
    },
    minDateValue: null,
  }),
  watch: {
    value: {
      immediate: false,
      handler(val) {
        console.log("monthPickerRange -> watch value", val);
        this.setPickerValue();
      }
    }
  },
  mounted() {
    console.log("deviceLang", this.deviceBasicLang);
    this.setPickerValue();
  },
  methods: {
    setPickerValue() {
      if (this.value == null) {
        console.log("monthPickerRange -> setPickerValue -> this.value == null");
        this.$emit("input", {[this.startKey]: this.$moment.startOf('month').format('YYYY-MM-DD'), [this.endKey]: this.$moment.endOf('month').format('YYYY-MM-DD')});
        return;
      }
      if (this.value[this.startKey] == null || this.value[this.endKey] == null) {
        console.log("monthPickerRange -> setPickerValue -> this.value[this.startKey] == null || this.value[this.endKey] == null")
        let start = this.value[this.startKey] || this.$moment().startOf('month').format('YYYY-MM-DD');
        let end = this.value[this.endKey] || this.$moment().endOf('month').format('YYYY-MM-DD');
        this.$emit("input", {[this.startKey]: start, [this.endKey]: end});
        return;
      }
      console.log("monthPickerRange -> setPickerValue", this.value);
      this.loadingData = true;
      if (this.value[this.startKey] !== this.stringValue.start) {
        let momentValue = this.$moment(this.value[this.startKey]);
        this.stringValue.start = momentValue.format("YYYY-MM-DD");
        let selection = this.defaultPickerValue.start = {year: momentValue.year(), monthIndex: momentValue.month()};
        this.$refs.startPicker.getMonthPicker().selectMonth(selection.monthIndex, false);
        this.$refs.startPicker.getMonthPicker().year = selection.year;
      }
      if (this.value[this.endKey] !== this.stringValue.end) {
        let momentValue = this.$moment(this.value[this.endKey]);
        this.stringValue.end = momentValue.format("YYYY-MM-DD");
        let selection = this.defaultPickerValue.start = {year: momentValue.year(), monthIndex: momentValue.month()};
        this.$refs.endPicker.getMonthPicker().selectMonth(selection.monthIndex, false);
        this.$refs.endPicker.getMonthPicker().year = selection.year;
        // this.$refs.endPicker.getMonthPicker().changeYear(selection.year);
      }
      this.loadingData = false;
    },
    onStartInput(value) {
      console.log("onStartInput", value);
      this.momentValue.start = this.$moment("" + value.year + "" + value.monthIndex, "YYYY-MM");
      this.stringValue.start = this.momentValue.start.format("YYYY-MM-DD");
      if(this.momentValue.end != null && this.momentValue.end.isBefore(this.momentValue.start)) {
        this.momentValue.end = this.momentValue.start.clone().endOf('month');
        this.stringValue.end = this.momentValue.end.format("YYYY-MM-DD");
        this.defaultPickerValue.end.monthIndex = this.momentValue.end.month();
        this.$refs.endPicker.getMonthPicker().selectMonth(this.defaultPickerValue.end.monthIndex, false);
        this.$refs.endPicker.getMonthPicker().year = this.momentValue.end.year();
      }
      this.minDateValue = this.momentValue.start.clone().startOf('month').toDate();
      let $this = this;
      setTimeout(() => {
        $this.$refs.endPicker.showMonthPicker();
      }, 100);

      // this.setInputValue();
    },
    onEndInput(value) {
      console.log("onEndInput", value);
      this.momentValue.end = this.$moment("" + value.year + "" + value.monthIndex, "YYYY-MM").endOf('month');
      this.stringValue.end = this.momentValue.end.format("YYYY-MM-DD");
      this.setInputValue();
    },
    setInputValue() {
      if (this.loadingData) {
        return;
      }
      let input = {[this.startKey]: this.stringValue.start, [this.endKey]: this.stringValue.end};
      console.log("setInputValue", input);
      this.$emit("input", input);
    }
  },
}
</script>

<style lang="scss">
.custom-month-picker{

  .month-picker__container{
    left: -200px;
  }

}
</style>
