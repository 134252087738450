<template>
  <div>
    <div v-if="!loading && series.length > 0 ">
      <vue-apex-charts
          ref="platformsDonutChart"
          type="pie"
          height="350"
          :options="chartOptions"
          :series="series"
      />
    </div>
    <div v-else-if="series.length === 0" style="margin-top: 150px; text-align: center">
      <b-alert show variant="warning" class="mt-2">
       {{$t('nodatafound')}}
      </b-alert>
    </div>
    <div class="mt-1" v-else style="max-height: 250px;overflow: hidden">
      <b-skeleton-img></b-skeleton-img>
    </div>
  </div>
</template>


<script>

import VueApexCharts from 'vue-apexcharts'
import { mapGetters } from 'vuex'

export default {
  name: "PlatformDoughnutChart",
  components: {
    VueApexCharts,
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    platformsData: {
      type: Array,
      default: () => []
    },
    showIncomes:{
      type: Boolean,
      default: false
    }
  },
  watch: {
    platformsData() {
      if (this.$refs['platformsDonutChart'] != null) {
        this.$refs['platformsDonutChart'].refresh()
      }
    }
  },
  computed: {
    ...mapGetters('appConfig', ['isDarkMode']),
    chartOptions() {
      let $this = this
      return {
        chart: {
          id: 'platforms-donut-chart',
          foreColor: $this.isDarkMode ? '#fafafa' :'#373d3f'
        },
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        colors: $this.platformsData.map((checkin) => $this.platformColors(checkin.platform)),
        labels: $this.platformsData.map((checkin) => `${checkin.platform} (${this.showIncomes ? checkin.netPayment + ' €' : checkin.count})`),
      }
    },
    series() {
      let $this = this;
      return $this.platformsData.map((checkin) => $this.showIncomes ? checkin.netPayment : checkin.count)
    }
  },
  methods: {
    refresh() {
      if (this.$refs['platformsDonutChart'] != null) {
        this.$refs['platformsDonutChart'].refresh()
      }
    },
    platformColors(platform){
      let options = {
        "Treasure Home": "#c2aa8e",
        "Airbnb": "#FF4560",
        "Booking": "#3F51B5",
        "Expedia": "#FF9800",
        "Agoda": "#4CAF50",
        "Vrbo": "#96a6b1",
        "Other": "#00BCD4",
      }
      return options[platform] || options["Other"];
    }
  }
}
</script>


<style scoped>

</style>
